import React, { useState, useEffect, useRef, useCallback } from "react";
import { useQuery, gql } from "@apollo/client";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Grid } from "@mui/material";
import { IconButton } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SkeletonPopularCard from "ui-component/cards/Skeleton/PopularCard";
// project imports
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);
export const options = {
  interaction: {
    intersect: false,
    mode: "index",
  },
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Sprint Velocity Trendline",
    },
  },
};

const VELOCITY = gql`
  query GetVelocity($workspace: ID!, $sprints: [ID!]) {
    workspace(id: $workspace) {
      id
      sprints(filters: { id: { in: $sprints } }) {
        nodes {
          name
          startAt
          totalPoints
          completedPoints
        }
      }
      __typename
    }
  }
`;

export const ChartVelocity = ({ isLoading, workspace, sprints }) => {
  const [chartInfo, setChartInfo] = useState({
    labels: [],
    datasets: [],
  });
  const { data, loading, error } = useQuery(VELOCITY, {
    variables: { workspace, sprints },
  });
  const ref = useRef(null);
  const downloadImage = useCallback(() => {
    const link = document.createElement("a");
    link.download = "Velocity.png";
    link.href = ref.current.toBase64Image();
    link.click();
  }, []);
  useEffect(() => {
    setChartInfo({
      labels: [],
      datasets: [],
    });
    if (data) {
      const velocityInfo = data.workspace.sprints.nodes;
      let value = 0;
      setChartInfo({
        ...chartInfo,
        labels: velocityInfo.map((a) => a.name.replace("Sprint:", "").trim()),
        datasets: [
          {
            label: "Average Sprint Velocity",
            data: velocityInfo.map(
              (a, index) => (value += a.completedPoints) / (index + 1)
            ),
            backgroundColor: "rgb(255, 99, 133)",
            borderColor: "rgb(255, 99, 133)",
            borderWidth: 1,
            tension: 0.3,
          },
          {
            label: "Total Story Points",
            data: velocityInfo.map((a) => a.totalPoints),
            backgroundColor: "rgb(255, 161, 64)",
            borderColor: "rgb(255, 161, 64)",
            borderWidth: 1,
            tension: 0.3,
          },
          {
            label: "Completed Story Points/Sprint Velocity",
            data: velocityInfo.map((a) => a.completedPoints),
            backgroundColor: "rgb(87, 150, 255)",
            borderColor: "rgb(87, 150, 255)",
            borderWidth: 1,
            tension: 0.3,
          },
        ],
      });
    }
  }, [data, workspace]);

  if (loading || error || isLoading) return <SkeletonPopularCard />;

  return (
    <MainCard>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <IconButton onClick={downloadImage}>
            <FileDownloadIcon fontSize="small" />
          </IconButton>
          <Line options={options} data={chartInfo} ref={ref} />
        </Grid>
      </Grid>
    </MainCard>
  );
};
