// assets
import { IconDashboard } from "@tabler/icons";
import PieChartIcon from '@mui/icons-material/PieChart';
import EditIcon from '@mui/icons-material/Edit';

// constant
const icons = { IconDashboard, PieChartIcon, EditIcon };

// ==============================|| Categorization MENU ITEMS ||============================== //

const categorization = {
  id: "categorization",
  title: "Categorization",
  type: "group",
  children: [
    {
      id: "retroData",
      title: "Retro Data",
      type: "item",
      url: "/retrodata",
      icon: icons.PieChartIcon,
      breadcrumbs: false,
    },
    {
      id: "edit",
      title: "Edit categories",
      type: "item",
      url: "/edit",
      icon: icons.EditIcon,
      breadcrumbs: false,
    },
  ],
};

export default categorization;
