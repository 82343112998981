import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useQuery, gql } from "@apollo/client";
// material-ui

import { Grid } from "@mui/material";
import { IconButton } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
// project imports
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import SkeletonPopularCard from "ui-component/cards/Skeleton/PopularCard";
import { parseCompletionTrend } from "utils/parser";

const HISTORYCHART = gql`
  query GetCompletionSprint($workspace: ID!, $sprint: ID!) {
    workspace(id: $workspace) {
      id
      sprints(filters: { id: { in: [$sprint] } }) {
        nodes {
          name
          startAt
          endAt
          totalPoints
          issues {
            nodes {
              id
              title
              estimate {
                value
              }
              closedAt
              __typename
            }
          }
        }
      }
      __typename
    }
  }
`;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);
const footer = (tooltipItems) => {
  let comp = 0;
  tooltipItems.forEach(function (tooltipItem) {
    comp = tooltipItems[0].dataset.data[0] - tooltipItems[0].raw;
  });
  if (tooltipItems.length > 1) {
    return "Completed: " + comp;
  }
};
export const options = {
  interaction: {
    intersect: false,
    mode: "index",
  },
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Sprint Burn Down Trend",
    },
    tooltip: {
      callbacks: {
        footer: footer,
      },
    },
  },
};

const CompletionTrend = ({ workspace, sprint }) => {
  const { data, loading, error } = useQuery(HISTORYCHART, {
    variables: { workspace, sprint },
  });
  const [chartInfo, setChartInfo] = useState({
    labels: [],
    datasets: [],
  });
  const ref = useRef(null);
  const downloadImage = useCallback(() => {
    const link = document.createElement("a");
    link.download =
      "SprintBurn(" + data.workspace.sprints.nodes[0].name + ").png";
    link.href = ref.current.toBase64Image();
    link.click();
  }, [data]);
  useEffect(() => {
    if (data) {
      const completionInfo = parseCompletionTrend(
        data.workspace.sprints.nodes[0]
      );

      setChartInfo({
        ...chartInfo,
        labels: completionInfo.dates,
        datasets: [
          {
            label: "Remaining",
            data: completionInfo.points,
            backgroundColor: "rgba(54, 162, 235, 0.1)",
            borderColor: "rgb(54, 162, 235)",
            borderWidth: 1,
            stepped: true,
            fill: true,
          },
          {
            label: "Ideal",
            data: completionInfo.ideal,
            backgroundColor: "rgba(192, 192, 192, 1)",
            borderColor: "rgb(192, 192, 192)",
            borderWidth: 1,
            borderDash: [5, 5],
          },
        ],
      });
    }
  }, [data]);
  if (loading || error) return <SkeletonPopularCard />;

  return (
    <MainCard>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <IconButton onClick={downloadImage}>
            <FileDownloadIcon fontSize="small" />
          </IconButton>
          <Line options={options} data={chartInfo} ref={ref} />
        </Grid>
      </Grid>
    </MainCard>
  );
};
export default CompletionTrend;
