import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

const columns = [
  { id: "sprint", label: "Sprint", minWidth: 170 },
  { id: "totalPoints", label: "Total Story Points", minWidth: 100 },
  {
    id: "completedPoints",
    label: "Completed Story Points",
    minWidth: 100,
    align: "center",
  },
  {
    id: "completion",
    label: "Completion %",
    minWidth: 100,
    align: "center",
  },
  {
    id: "totalIssues",
    label: "Total Issues",
    minWidth: 100,
    align: "center",
  },
  {
    id: "closedIssues",
    label: "Closed Issues",
    minWidth: 100,
    align: "center",
  },
  {
    id: "closure",
    label: "Closure %",
    minWidth: 100,
    align: "center",
  },
];

export default function EnhancedTable({ rows }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <TableCell key="sprint" align="center">
                      {row.name.replace("Sprint:", "").trim()}
                    </TableCell>
                    <TableCell key="totalPoints" align="center">
                      {row.totalPoints}
                    </TableCell>
                    <TableCell key="completedPoints" align="center">
                      {row.completedPoints}
                    </TableCell>
                    <TableCell key="completion" align="center">
                      {((row.completedPoints / row.totalPoints) * 100).toFixed(
                        0
                      )}
                    </TableCell>
                    <TableCell key="totalIssues" align="center">
                      {row.issues.totalCount}
                    </TableCell>
                    <TableCell key="completedIssues" align="center">
                      {row.closedIssuesCount}
                    </TableCell>
                    <TableCell key="closure" align="center">
                      {(
                        (row.closedIssuesCount / row.issues.totalCount) *
                        100
                      ).toFixed(0)}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
