import React, { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
// material-ui
import { Grid, MenuItem } from "@mui/material";
import SkeletonPopularCard from "ui-component/cards/Skeleton/PopularCard";
import MainCard from "ui-component/cards/MainCard";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
// project imports

import { gridSpacing } from "store/constant";
import ChartSprintHistory from "./ChartSprintHistory";
import EnhancedTable from "./EnhancedTable";
import CompletionTrend from "./CompletionTrend";

const HISTORY = gql`
  query GetHistory($workspace: ID!) {
    workspace(id: $workspace) {
      id
      sprints {
        totalCount
        nodes {
          id
          createdAt
          startAt
          endAt
          name
          state
          issues {
            totalCount
          }
          closedIssuesCount
          totalPoints
          completedPoints
          __typename
        }
      }
      __typename
    }
  }
`;

// ==============================|| DEFAULT DASHBOARD ||============================== //

const HistoryPage = ({ workspace }) => {
  const { data, loading, error } = useQuery(HISTORY, {
    variables: { workspace },
  });
  const [historyInfo, setHistoryInfo] = useState(null);
  const [sprint, setSprint] = useState("");
  const onSprintSelect = (event) => {
    setSprint(event.target.value);
  };
  useEffect(() => {
    if (data) {
      const now = new Date().getTime();
      const dataReversed = data.workspace.sprints.nodes
        .filter((sprint) => new Date(sprint.startAt).getTime() < now)
        .reverse();
      setHistoryInfo(dataReversed);
      setSprint(dataReversed[0].id);
    }
  }, [data]);
  if (!workspace) return null;
  if (loading || error || !historyInfo) return <SkeletonPopularCard />;
  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12} md={12}>
            <ChartSprintHistory
              workspace={workspace}
              sprints={historyInfo.slice(0).reverse()}
            />
          </Grid>
        </Grid>
      </Grid>
      <FormControl sx={{ marginLeft: 2, marginTop: 2 }}>
        <InputLabel id="sprint-select-label">Sprint</InputLabel>
        <Select
          labelId="sprint-select-label"
          id="sprint-select"
          value={sprint}
          label="Sprint"
          onChange={onSprintSelect}
        >
          {historyInfo.map((sprint, index) => {
            return (
              <MenuItem key={index} value={sprint.id}>
                {sprint.name.replace("Sprint: ", "")}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12} md={12}>
            <CompletionTrend workspace={workspace} sprint={sprint} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12} md={12}>
            <MainCard>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                  <EnhancedTable rows={historyInfo} />
                </Grid>
              </Grid>
            </MainCard>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HistoryPage;
