// assets
import { IconDashboard } from "@tabler/icons";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import SpeedIcon from "@mui/icons-material/Speed";

// constant
const icons = { IconDashboard, PersonSearchIcon, SpeedIcon };

// ==============================|| Assignees MENU ITEMS ||============================== //

const assignees = {
  id: "assignees",
  title: "Assignees",
  type: "group",
  children: [
    {
      id: "assigneesInfo",
      title: "Info",
      type: "item",
      url: "/assignees-info",
      icon: icons.PersonSearchIcon,
      breadcrumbs: false,
    },
    {
      id: "assigneesVelocity",
      title: "Velocity",
      type: "item",
      url: "/assignees-velocity",
      icon: icons.SpeedIcon,
      breadcrumbs: false,
    },
  ],
};

export default assignees;
