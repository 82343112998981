import dashboard from "./dashboard";
import pages from "./pages";
import categorization from "./categorization";
import assignees from "./assignees";
import roadmap from "./roadmap";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = sessionStorage.getItem("jwToken")
  ? {
      items: [dashboard, categorization, assignees, pages],
    }
  : {
      items: [dashboard, categorization, assignees, roadmap],
    };

export default menuItems;
