import React, { useEffect, useState } from "react";
// material-ui
import { Grid, MenuItem } from "@mui/material";
import { useQuery, gql } from "@apollo/client";
// project imports
import SkeletonPopularCard from "ui-component/cards/Skeleton/PopularCard";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";

import EnhancedTable from "./EnhancedTable";
import CompletionTrend from "./CompletionTrend";
import { getDaysArray, parseAssignees } from "utils/parser";

const ASSIGNEES = gql`
  query GetAssignees($workspace: ID!) {
    workspace(id: $workspace) {
      id
      sprints {
        nodes {
          id
          startAt
          name
          __typename
        }
      }
      activeSprint {
        id
        startAt
        endAt
        issues {
          nodes {
            id
            title
            state
            closedAt
            estimate {
              value
            }
            pipelineIssue(workspaceId: $workspace) {
              pipeline {
                name
              }
            }
            assignees {
              nodes {
                name
                avatarUrl
                login
              }
            }
            __typename
          }
        }
        __typename
      }
      __typename
    }
  }
`;

// ==============================|| DEFAULT DASHBOARD ||============================== //

const AssigneesInfoPage = ({ workspace, loggedIn }) => {
  const { data, loading, error } = useQuery(ASSIGNEES, {
    variables: { workspace },
  });
  const [assigneeInfo, setAssigneeInfo] = useState(null);
  const [assignee, setAssignee] = useState("");
  const [sprint, setSprint] = useState("");
  const onAssigneeSelect = (event) => {
    setAssignee(event.target.value);
  };
  const onSprintSelect = (event) => {
    setSprint(event.target.value);
  };
  useEffect(() => {
    setAssigneeInfo(null);
    setAssignee(null);
    if (data?.workspace.activeSprint) {
      const assigneesParsed = parseAssignees(data);
      setSprint(
        data.workspace.sprints.nodes
          .filter(
            (sprint) =>
              new Date(sprint.startAt).getTime() < new Date().getTime()
          )
          .reverse()[0].id
      );
      setAssignee(assigneesParsed[0]?.login);
      setAssigneeInfo(assigneesParsed);
    }
  }, [data]);
  if (!workspace) return null;
  if (
    loading ||
    error ||
    !assigneeInfo ||
    !assignee ||
    !data.workspace.activeSprint
  )
    return <SkeletonPopularCard />;
  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12} md={12}>
            <MainCard>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                  <EnhancedTable
                    rows={assigneeInfo}
                    sprint={
                      data.workspace.sprints.nodes[
                        data.workspace.sprints.nodes.findIndex(
                          (sprint) =>
                            sprint.id === data.workspace.activeSprint.id
                        ) + 1
                      ].id
                    }
                    workspace={workspace}
                  />
                </Grid>
              </Grid>
            </MainCard>
          </Grid>
        </Grid>
      </Grid>
      <FormControl sx={{ marginLeft: 2, marginTop: 2 }}>
        <InputLabel id="assignee-select-label">Assignee</InputLabel>
        <Select
          labelId="assignee-select-label"
          id="assignee-select"
          value={assignee}
          label="Assignee"
          onChange={onAssigneeSelect}
        >
          {assigneeInfo.map((assignee, index) => {
            return (
              <MenuItem key={index} value={assignee.login}>
                {assignee.login}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl sx={{ marginLeft: 2, marginTop: 2 }}>
        <InputLabel id="sprint-select-label">Sprint</InputLabel>
        <Select
          labelId="sprint-select-label"
          id="sprint-select"
          value={sprint}
          label="Sprint"
          onChange={onSprintSelect}
        >
          {data.workspace.sprints.nodes
            .filter(
              (sprint) =>
                new Date(sprint.startAt).getTime() < new Date().getTime()
            )
            .reverse()
            .map((sprint, index) => {
              return (
                <MenuItem key={index} value={sprint.id}>
                  {sprint.name.replace("Sprint: ", "")}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12} md={12}>
            {assigneeInfo.length > 0 ? (
              <CompletionTrend
                workspace={workspace}
                sprint={sprint}
                assignee={assignee}
              />
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AssigneesInfoPage;
