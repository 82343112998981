// material-ui
import { Grid } from "@mui/material";
import { useQuery, gql } from "@apollo/client";
// project imports

import { gridSpacing } from "store/constant";
import { ChartVelocity } from "./ChartVelocity";

const SPRINTSVELOCITY = gql`
  query GetSprintsVelocity($workspace: ID!) {
    workspace(id: $workspace) {
      id
      sprints {
        nodes {
          id
          name
          startAt
          __typename
        }
      }
      __typename
    }
  }
`;

// ==============================|| DEFAULT DASHBOARD ||============================== //

const VelocityPage = ({ workspace }) => {
  const { data, loading, error } = useQuery(SPRINTSVELOCITY, {
    variables: { workspace },
  });
  if (!workspace) return null;
  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12} md={12}>
            <ChartVelocity
              isLoading={loading}
              workspace={workspace}
              sprints={data?.workspace.sprints.nodes.map((a) =>
                new Date(a.startAt).getTime() < new Date().getTime()
                  ? a.id
                  : null
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VelocityPage;
