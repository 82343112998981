import { useRoutes, Navigate } from "react-router-dom";
import { lazy, useState } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import { useSessionStorage } from "../hooks/useSessionStorage.js";
// routes
import AuthenticationRoutes from "./AuthenticationRoutes";
import VelocityPage from "views/dashboard/velocity";
import HistoryPage from "views/dashboard/history";
import AssigneesInfoPage from "views/assignees/info/index.js";
import AssigneesVelocityPage from "views/assignees/velocity/index.js";
import RoadmapPage from "views/roadmap/default/index.js";

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);

// pivot page routing
const PivotPage = Loadable(lazy(() => import("views/dashboard/pivot")));
// scope change page routing
const ScopePage = Loadable(lazy(() => import("views/dashboard/scope")));
const CategoriesPage = Loadable(
  lazy(() => import("views/categorization/edit"))
);
const RetroDataPage = Loadable(
  lazy(() => import("views/categorization/retroData"))
);

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const loggedIn = useSessionStorage("sessionJWTToken");
  const [workspace, setWorkspace] = useState(null);
  const setSearchWorkspace = (workspace) => {
    if (workspace) {
      setWorkspace(workspace);
    } else {
      setWorkspace(null);
    }
  };

  return useRoutes([
    {
      path: "/",
      element: loggedIn ? (
        <MainLayout setSearchWorkspace={setSearchWorkspace} />
      ) : (
        <Navigate to="/pages/login" />
      ),
      children: [
        {
          path: "/",
          element: (
            <DashboardDefault workspace={workspace} loggedIn={loggedIn} />
          ),
        },
        {
          path: "pivot",
          element: <PivotPage workspace={workspace} loggedIn={loggedIn} />,
        },
        {
          path: "scope",
          element: <ScopePage workspace={workspace} loggedIn={loggedIn} />,
        },
        {
          path: "velocity",
          element: <VelocityPage workspace={workspace} loggedIn={loggedIn} />,
        },

        {
          path: "history",
          element: <HistoryPage workspace={workspace} loggedIn={loggedIn} />,
        },
        {
          path: "retrodata",
          element: <RetroDataPage workspace={workspace} loggedIn={loggedIn} />,
        },
        {
          path: "edit",
          element: <CategoriesPage loggedIn={loggedIn} />,
        },
        {
          path: "assignees-info",
          element: (
            <AssigneesInfoPage workspace={workspace} loggedIn={loggedIn} />
          ),
        },
        {
          path: "assignees-velocity",
          element: (
            <AssigneesVelocityPage workspace={workspace} loggedIn={loggedIn} />
          ),
        },
        {
          path: "roadmap-info",
          element: <RoadmapPage workspace={workspace} loggedIn={loggedIn} />,
        },
      ],
    },
    AuthenticationRoutes,
  ]);
}
