import pLimit from "p-limit";

const token = process.env.REACT_APP_TOKEN;
// Get Roadmap Information
const getRoadmaps = (workspace) => {
  const responseInfo = fetch("https://api.zenhub.com/public/graphql", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("zhToken"),
    },
    body: JSON.stringify({
      query: `
          {
            workspace(id: "${workspace}") {
               releases(state: { eq: OPEN }) {
        nodes {
          id
          title
          createdAt
          description
          endOn
          issues {
            nodes {
              ghId
              id
              title
              body
              createdAt
              closedAt
              estimate {
                value
              }
            }
          }
        }
      }
            }
          }
      `,
    }),
  });
  return responseInfo;
};

// Get Epics Information
const getEpics = (workspace) => {
  const responseInfo = fetch("https://api.zenhub.com/public/graphql", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("zhToken"),
    },
    body: JSON.stringify({
      query: `
          {
            workspace(id: "${workspace}") {
               epics {
        nodes {
          id
          issue {
            createdAt
            closedAt
            ghId
            id
            title
          }
        }
      }
            }
          }
      `,
    }),
  });
  return responseInfo;
};
// Get Epic Issues Information
const getEpicIssues = (epicId) => {
  const responseInfo = fetch("https://api.zenhub.com/public/graphql", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("zhToken"),
    },
    body: JSON.stringify({
      query: `
          {
             node(id: "${epicId}") {
               ... on Epic {
                id
                childIssues {
                  nodes {
                    id
                    state
                    title
                    body
                    createdAt
                    closedAt
                    estimate {
                      value
                    }
                    assignees {
                    nodes {
                      login
                    }
                  }
                }
              } 
            } 
          }
        }
      `,
    }),
  });
  return responseInfo;
};

export async function getRoadmapData(workspace) {
  try {
    const [roadmapResponse, epicsResponse] = await Promise.all([
      getRoadmaps(workspace),
      getEpics(workspace),
    ]);

    const [roadmapData, epicsData] = await Promise.all([
      roadmapResponse.json(),
      epicsResponse.json(),
    ]);

    const roadmap = roadmapData.data.workspace.releases.nodes.map((release) => {
      const epics = epicsData.data.workspace.epics.nodes
        .filter((epic) => {
          const epicGhId = epic.issue.ghId;
          return release.issues.nodes.some((issue) => issue.ghId === epicGhId);
        })
        .map((epic) => ({
          name: epic.issue.title,
          id: epic.id,
          start: new Date(epic.issue.createdAt),
          end: epic.issue.closedAt ? new Date(epic.issue.closedAt) : new Date(),
          progress: 0,
          isDisabled: false,
          issues: [],
        }));

      return {
        name: release.title,
        start: new Date(release.createdAt),
        end: new Date(release.endOn),
        id: release.id,
        progress: 0,
        isDisabled: false,
        epics,
      };
    });

    const limit = pLimit(50);

    await Promise.all(
      roadmap.flatMap((roadmapItem) =>
        roadmapItem.epics.map((epic) =>
          limit(async () => {
            const epicIssuesResponse = await getEpicIssues(epic.id);
            const epicIssuesData = await epicIssuesResponse.json();
            const issues = epicIssuesData.data.node.childIssues.nodes.map(
              (issue) => ({
                id: issue.id,
                name: issue.title,
                start: new Date(issue.createdAt),
                end: issue.closedAt ? new Date(issue.closedAt) : new Date(),
                progress: 0,
                isDisabled: false,
              })
            );
            epic.issues = issues;
          })
        )
      )
    );

    return roadmap;
  } catch (error) {
    console.error("Error fetching roadmap data:", error);
    return null;
  }
}
