import React from "react";
// material-ui
import { Grid } from "@mui/material";
import { useQuery, gql } from "@apollo/client";
// project imports
import SkeletonPopularCard from "ui-component/cards/Skeleton/PopularCard";

import { gridSpacing } from "store/constant";

import ChartVelocity from "./ChartVelocity";

const SPRINTS = gql`
  query GetSprints($workspace: ID!) {
    workspace(id: $workspace) {
      id
      sprints {
        nodes {
          id
          startAt
          name
          __typename
        }
      }
      __typename
    }
  }
`;

// ==============================|| DEFAULT DASHBOARD ||============================== //

const AssigneesVelocityPage = ({ workspace, loggedIn }) => {
  const { data, loading, error } = useQuery(SPRINTS, {
    variables: { workspace },
  });

  if (!workspace) return null;
  if (loading || error) return <SkeletonPopularCard />;
  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12} md={12}>
            <ChartVelocity
              workspace={workspace}
              sprints={data.workspace.sprints.nodes.map((sprint) => sprint.id)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AssigneesVelocityPage;
