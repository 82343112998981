export const parseSprintRetro = (categories, data) => {
  let response = [];
  let totalAssignees = [];
  let totalTeams = [];
  const now = new Date().getTime();
  data.forEach((sprint, index, sprints) => {
    const sprintStart = new Date(sprint.startAt).getTime();
    if (sprintStart < now) {
      let sprintObject = {};
      sprintObject.id = sprint.id;
      sprintObject.name = sprint.name;
      sprintObject.issues = [];
      sprint.issues.nodes.forEach((issue) => {
        let issueObject = {};
        issueObject.id = issue.id;
        issueObject.title = issue.title;
        issueObject.state = issue.state;

        issueObject.team = issue.htmlUrl.split("/issues/")[0].split("/")[
          issue.htmlUrl.split("/issues/")[0].split("/").length - 1
        ];
        totalTeams.push(issueObject.team);
        issueObject.points = issue.estimate?.value || 0;
        issueObject.assignees = [];
        issue.assignees.nodes.forEach((assignee) => {
          totalAssignees.push(assignee.name);
          issueObject.assignees.push(assignee.name);
        });
        issueObject.rolled = 0;

        for (var i = index - 1; i >= 0; i--) {
          if (
            sprints[i].issues.nodes.some((oldIssue) => oldIssue.id === issue.id)
          ) {
            issueObject.rolled += 1;
          }
        }
        categories.forEach((category) => {
          if (
            category.issues.some(
              (e) => e.issueId === issue.id && e.sprintId === sprint.id
            )
          ) {
            issueObject.categoryId = category._id;
            issueObject.category = category.name;
          }
        });
        sprintObject.issues.push(issueObject);
      });
      response.push(sprintObject);
    }
  });
  let responseAssignees = [...new Set(totalAssignees)];
  let responseTeams = [...new Set(totalTeams)];
  return [response.reverse(), responseAssignees.sort(), responseTeams.sort()];
};

export const getDaysArray = function (s, e) {
  let numWorkDays = 0;
  for (
    var a = [], d = new Date(s);
    d <= new Date(e);
    d.setDate(d.getDate() + 1)
  ) {
    a.push(new Date(d).toISOString().split("T")[0]);
    if (new Date(d).getDay() % 6 !== 0) {
      numWorkDays += 1;
    }
  }
  return [a, numWorkDays];
};

export const parseCompletionTrend = (sprint) => {
  const [dates, numWorkDays] = getDaysArray(sprint.startAt, sprint.endAt);

  const pointsPerDay = Math.ceil(sprint.totalPoints / numWorkDays);
  let points = new Array(dates.length).fill(0);
  sprint.issues.nodes.forEach((node) => {
    if (node.closedAt && node.estimate) {
      points[dates.indexOf(node.closedAt.split("T")[0])] =
        points[dates.indexOf(node.closedAt.split("T")[0])] +
        node.estimate.value;
    }
  });
  let real = sprint.totalPoints;
  let pred = sprint.totalPoints;
  let ideal = [];
  points.forEach((day, index, array) => {
    if (new Date(dates[index]).getTime() < new Date().getTime()) {
      array[index] = real - day;
      real = array[index];
    } else {
      array[index] = null;
    }
    if (new Date(dates[index]).getDay() % 6 !== 0) {
      ideal.push(pred < pointsPerDay ? 0 : pred - pointsPerDay);
    } else {
      ideal.push(pred);
    }
    pred = ideal[index];
  });
  return { dates, points, ideal };
};

export const parsePipeline = (data) => {
  let response = {};
  data.forEach((node) => {
    if (node.estimate && node.state === "OPEN") {
      if (response.hasOwnProperty(node.pipelineIssue.pipeline.name)) {
        response[node.pipelineIssue.pipeline.name] =
          response[node.pipelineIssue.pipeline.name] + node.estimate.value;
      } else {
        response[node.pipelineIssue.pipeline.name] = node.estimate.value;
      }
    }
  });
  return response;
};

export const parseAssignees = (data) => {
  const [dates, numWorkDays] = getDaysArray(
    data.workspace.activeSprint.startAt,
    data.workspace.activeSprint.endAt
  );
  let assigneeData = [];
  data.workspace.activeSprint.issues.nodes.forEach((issue) => {
    if (issue.estimate) {
      issue.assignees.nodes.forEach((assignee) => {
        // Find if we have the assignee in our list
        const indexAssignee = assigneeData.findIndex(
          (el) => el.login === assignee.login
        );
        // If the assignee is in our list we add the extra points
        if (indexAssignee !== -1) {
          // If the pipeline is not in the assigne yet we add it to the list
          if (
            !assigneeData[indexAssignee].issues[
              issue.pipelineIssue.pipeline.name.split(" ").pop()
            ]
          ) {
            assigneeData[indexAssignee].issues[
              issue.pipelineIssue.pipeline.name.split(" ").pop()
            ] = {
              OPEN: 0,
              CLOSED: 0,
              SHARED: issue.assignees.nodes.length > 1,
            };
          }
          assigneeData[indexAssignee].issues[
            issue.pipelineIssue.pipeline.name.split(" ").pop()
          ][issue.state] += issue.estimate.value;
          if (
            assigneeData[indexAssignee].issues[
              issue.pipelineIssue.pipeline.name.split(" ").pop()
            ]["SHARED"] === false &&
            issue.assignees.nodes.length > 1
          ) {
            assigneeData[indexAssignee].issues[
              issue.pipelineIssue.pipeline.name.split(" ").pop()
            ]["SHARED"] = true;
          }
          // If the issue was closed add the number of points to the day of the array
          assigneeData[indexAssignee].issues.Total += issue.estimate.value;
          // If the issue was closed add the number of points to the day of the array
          if (issue.closedAt) {
            assigneeData[indexAssignee].issues.Total += issue.estimate.value;
            // If closing date is before sprint we add the points to the first day of the sprint
            if (dates.indexOf(issue.closedAt.split("T")[0]) !== -1) {
              assigneeData[indexAssignee].chart[
                dates.indexOf(issue.closedAt.split("T")[0])
              ] += issue.estimate.value;
            }
          }
          // If the assignee is NOT in our list we create the assignee and the points
        } else {
          // Create assignee info
          let assigneeToPush = { ...assignee };
          // Add the pipeline name and Total with 0
          assigneeToPush["issues"] = {
            Total: 0,
          };
          assigneeToPush.issues[
            issue.pipelineIssue.pipeline.name.split(" ").pop()
          ] = {
            OPEN: 0,
            CLOSED: 0,
            SHARED: issue.assignees.nodes.length > 1,
          };
          // Add the current pipeline info
          assigneeToPush.issues[
            issue.pipelineIssue.pipeline.name.split(" ").pop()
          ][issue.state] += issue.estimate.value;
          // Add points to Total
          assigneeToPush.issues.Total += issue.estimate.value;
          // Create the array for the chart with 0s
          assigneeToPush["chart"] = new Array(dates.length).fill(0);
          // If the issue was closed add the number of points to the day of the array
          if (issue.closedAt) {
            assigneeToPush.issues.Total += issue.estimate.value;
            // If closing date is before sprint we add the points to the first day of the sprint
            if (dates.indexOf(issue.closedAt.split("T")[0]) !== -1) {
              assigneeToPush.chart[
                dates.indexOf(issue.closedAt.split("T")[0])
              ] = issue.estimate.value;
            } else {
              assigneeToPush.chart[0] = issue.estimate.value;
            }
          }
          assigneeData.push(assigneeToPush);
        }
      });
    }
  });
  return assigneeData;
};

export const parseAssigneesChart = (data, assigneeLogin, dates) => {
  let total = 0;
  // Create the array for the chart with 0s
  let chart = new Array(dates.length).fill(0);
  data.workspace.sprints.nodes[0].issues.nodes.forEach((issue) => {
    if (issue.estimate) {
      issue.assignees.nodes.forEach((assignee) => {
        if (assignee.login === assigneeLogin) {
          total += issue.estimate.value;
          if (issue.closedAt) {
            if (dates.indexOf(issue.closedAt.split("T")[0]) !== -1) {
              // If the issue was closed add the number of points to the day of the array
              chart[dates.indexOf(issue.closedAt.split("T")[0])] +=
                issue.estimate.value;
            } else {
              // If closing date is before sprint we add the points to the first day of the sprint
              chart[0] += issue.estimate.value;
            }
          }
        }
      });
    }
  });
  return { chart, total };
};
export const parseAssigneesVelocity = (data) => {
  let velocityData = [
    {
      login: "Total_Average",
      total: 0,
    },
  ];
  let arrayLabels = [];
  data.workspace.sprints.nodes.forEach((sprint) => {
    if (new Date(sprint.startAt).getTime() < new Date().getTime()) {
      arrayLabels.push(sprint.name);
      sprint.issues.nodes.forEach((issue) => {
        if (
          issue.estimate &&
          issue.assignees.nodes.length > 0 &&
          new Date(sprint.startAt).getTime() <
            new Date(issue.closedAt).getTime() <
            new Date(sprint.endAt).getTime()
        ) {
          issue.assignees.nodes.forEach((assignee) => {
            const indexAssignee = velocityData.findIndex(
              (el) => el.login === assignee.login
            );
            if (indexAssignee !== -1) {
              if (velocityData[indexAssignee][sprint.name]) {
                velocityData[indexAssignee][sprint.name] +=
                  issue.estimate.value;
              } else {
                velocityData[indexAssignee][sprint.name] =
                  issue.estimate.value + velocityData[indexAssignee].total;
              }
              velocityData[indexAssignee].total += issue.estimate.value;
              if (velocityData[0][sprint.name]) {
                velocityData[0][sprint.name] += issue.estimate.value;
              } else {
                velocityData[0][sprint.name] =
                  issue.estimate.value + velocityData[0].total;
              }
            } else {
              velocityData.push({
                login: assignee.login,
                [sprint.name]: issue.estimate.value,
                total: issue.estimate.value,
              });
              if (velocityData[0][sprint.name]) {
                velocityData[0][sprint.name] += issue.estimate.value;
              } else {
                velocityData[0][sprint.name] =
                  issue.estimate.value + velocityData[0].total;
              }
            }
          });
        }
      });
    }
  });

  Object.keys(velocityData[0]).forEach((sprint) => {
    if (sprint !== "login") {
      velocityData[0][sprint] =
        velocityData[0][sprint] / (velocityData.length - 1);
    }
  });

  return [velocityData, arrayLabels];
};
