// assets
import { IconMapPin } from "@tabler/icons";

// constant
const icons = { IconMapPin };

// ==============================|| Roadmap MENU ITEMS ||============================== //

const roadmap = {
  id: "roadmap",
  title: "Roadmap",
  type: "group",
  children: [
    {
      id: "roadmapInfo",
      title: "Info",
      type: "item",
      url: "/roadmap-info",
      icon: icons.IconMapPin,
      breadcrumbs: false,
    },
  ],
};

export default roadmap;
