import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

import { Grid } from "@mui/material";
import { IconButton } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";

// project imports

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);
export const options = {
  interaction: {
    intersect: false,
    mode: "index",
  },
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Sprint History",
    },
  },
};

const ChartSprintHistory = ({ sprints }) => {
  const [chartInfo, setChartInfo] = useState({
    labels: [],
    datasets: [],
  });
  const ref = useRef(null);
  const downloadImage = useCallback(() => {
    const link = document.createElement("a");
    link.download = "History.png";
    link.href = ref.current.toBase64Image();
    link.click();
  }, []);
  useEffect(() => {
    let sprintName = [];
    let totalPoints = [];
    let completedPoints = [];
    let totalIssues = [];
    let closedIssues = [];

    sprints.forEach((node) => {
      sprintName.push(node.name.replace("Sprint:", "").trim());
      totalPoints.push(node.totalPoints);
      completedPoints.push(node.completedPoints);
      totalIssues.push(node.issues.totalCount);
      closedIssues.push(node.issuesCount);
    });

    setChartInfo({
      ...chartInfo,
      labels: sprintName,
      datasets: [
        {
          label: "Total Story Points",
          data: totalPoints,
          backgroundColor: "rgba(54, 162, 235, 0.5)",
          borderColor: "rgb(54, 162, 235)",
          borderWidth: 1,
          tension: 0.3,
        },
        {
          label: "Completed Story Points",
          data: completedPoints,
          backgroundColor: "rgba(22, 55, 222, 0.5)",
          borderColor: "rgb(22, 55, 222)",
          borderWidth: 1,
          tension: 0.3,
        },
        {
          label: "Total Issues",
          data: totalIssues,
          backgroundColor: "rgba(222, 155, 22, 0.5)",
          borderColor: "rgb(222, 155, 22)",
          borderWidth: 1,
          tension: 0.3,
        },
        {
          label: "Closed Issues",
          data: closedIssues,
          backgroundColor: "rgba(222, 42, 22, 0.5)",
          borderColor: "rgb(222, 42, 22)",
          borderWidth: 1,
          tension: 0.3,
        },
      ],
    });
  }, [sprints]);
  return (
    <MainCard>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <IconButton onClick={downloadImage}>
            <FileDownloadIcon fontSize="small" />
          </IconButton>
          <Line options={options} data={chartInfo} ref={ref} />
        </Grid>
      </Grid>
    </MainCard>
  );
};
export default ChartSprintHistory;
