import axios from "../utils/config/axios.config";
export const register = (email, token, password, id) => {
  // Declare Body to post
  const body = {
    email: email,
    token: token,
    password: password,
    id: id,
  };
  // Send PUT request
  return axios.post("/auth/register", body);
};

export const login = (email, password) => {
  // Declare Body to post
  const body = {
    email: email,
    password: password,
  };
  // Send PUT request
  return axios.post("/auth/login", body);
};

export const getMe = (id, token) => {
  const options = {
    headers: {
      "x-access-token": token,
    },
  };
  // Send GET request
  return axios.get("/auth/me?id=" + id, options);
};

export const getRegistrationID = (id, token) => {
  const options = {
    headers: {
      "x-access-token": token,
    },
  };
  // Send GET request
  return axios.get("/auth/generate?id=" + id, options);
};
