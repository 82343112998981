import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useQuery, gql } from "@apollo/client";
// material-ui

import { Button, Grid } from "@mui/material";
import { IconButton } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

// project imports
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import SkeletonPopularCard from "ui-component/cards/Skeleton/PopularCard";
import { parseAssigneesVelocity } from "utils/parser";
import { random_rgba } from "utils/calcs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Assignee Velocity Trendline",
    },
  },
};

const ASSIGNEESVELOCITY = gql`
  query GetVelocityData($workspace: ID!, $sprints: [ID!]) {
    workspace(id: $workspace) {
      id
      sprints(filters: { id: { in: $sprints } }) {
        nodes {
          id
          name
          startAt
          endAt
          issues {
            nodes {
              id
              closedAt
              estimate {
                value
              }
              assignees(first: 10) {
                nodes {
                  login
                }
              }
            }
          }
        }
      }
    }
  }
`;

const ChartVelocity = ({ workspace, sprints }) => {
  const { data, loading, error } = useQuery(ASSIGNEESVELOCITY, {
    variables: { workspace, sprints },
  });
  const [showChart, setShowChart] = useState(true);
  const [chartInfo, setChartInfo] = useState({
    labels: [],
    datasets: [],
  });
  const handleToggle = () => {
    showChart ? setShowChart(false) : setShowChart(true);
    setChartInfo({
      ...chartInfo,
      datasets: chartInfo.datasets.map((dataset) => ({
        label: dataset.label,
        data: dataset.data,
        backgroundColor: dataset.backgroundColor,
        borderColor: dataset.borderColor,
        borderWidth: dataset.borderWidth,
        tension: dataset.tension,
        hidden: showChart,
      })),
    });
  };
  const ref = useRef(null);
  const downloadImage = useCallback(() => {
    const link = document.createElement("a");
    link.download = "AssigneesVelocity.png";
    link.href = ref.current.toBase64Image();
    link.click();
  }, []);
  useEffect(() => {
    if (data) {
      const [velocityData, arrayLabels] = parseAssigneesVelocity(data);

      let assigneesChart = [];
      velocityData.forEach((assignee) => {
        if (assignee.login === "Total_Average") {
          const color = "black";
          assigneesChart.push({
            label: assignee.login,
            data: arrayLabels.map((a) => assignee[a] || 0),
            backgroundColor: color,
            borderColor: color,
            borderWidth: 5,
            tension: 0.3,
            hidden: false,
          });
        } else {
          const color = random_rgba();
          assigneesChart.push({
            label: assignee.login,
            data: arrayLabels.map((a, index) => assignee[a] / (index + 1) || 0),
            backgroundColor: color,
            borderColor: color,
            borderWidth: 1,
            tension: 0.3,
            hidden: false,
          });
        }
      });
      setChartInfo({
        ...chartInfo,
        labels: arrayLabels.map((a) => a.replace("Sprint:", "").trim()),
        datasets: assigneesChart,
      });
    }
  }, [data]);
  if (loading || error) return <SkeletonPopularCard />;
  return (
    <MainCard>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <IconButton onClick={downloadImage}>
            <FileDownloadIcon fontSize="small" />
          </IconButton>
          <Button variant="outlined" onClick={handleToggle}>
            {showChart ? "Hide All" : "Select All"}
          </Button>
          <Line options={options} data={chartInfo} ref={ref} />
        </Grid>
      </Grid>
    </MainCard>
  );
};
export default ChartVelocity;
