import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
// material-ui
import { useTheme, styled } from "@mui/material/styles";
import {
  Avatar,
  Box,
  ButtonBase,
  Card,
  Grid,
  InputAdornment,
  OutlinedInput,
  Popper,
  TextField,
  MenuItem,
} from "@mui/material";

// third-party
import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";

// project imports
import Transitions from "ui-component/extended/Transitions";

// assets
import { IconAdjustmentsHorizontal, IconSearch, IconX } from "@tabler/icons";
import { shouldForwardProp } from "@mui/system";
import { Navigate, useNavigate } from "react-router";

// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
  zIndex: 1100,
  width: "99%",
  top: "-55px !important",
  padding: "0 12px",
  [theme.breakpoints.down("sm")]: {
    padding: "0 10px",
  },
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(
  ({ theme }) => ({
    width: 434,
    marginLeft: 16,
    paddingLeft: 16,
    paddingRight: 16,
    "& input": {
      background: "transparent !important",
      paddingLeft: "4px !important",
    },
    [theme.breakpoints.down("lg")]: {
      width: 250,
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 4,
      background: "#fff",
    },
  })
);

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(
  ({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
    "&:hover": {
      background: theme.palette.secondary.dark,
      color: theme.palette.secondary.light,
    },
  })
);

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = ({ popupState, onWorkspaceSelect, workspace, data }) => {
  const theme = useTheme();

  return (
    <Grid container alignItems="center" gap={2}>
      <TextField
        id="standard-select-workspace"
        select
        value={workspace}
        onChange={onWorkspaceSelect}
      >
        {data.viewer.workspaceFavorites.nodes.map((w, index) => {
          return (
            <MenuItem key={index} value={w.workspace.id}>
              {w.workspace.name}
            </MenuItem>
          );
        })}
      </TextField>
      <ButtonBase sx={{ borderRadius: "12px" }}>
        <Avatar
          variant="rounded"
          sx={{
            ...theme.typography.commonAvatar,
            ...theme.typography.mediumAvatar,
            background: theme.palette.orange.light,
            color: theme.palette.orange.dark,
            "&:hover": {
              background: theme.palette.orange.dark,
              color: theme.palette.orange.light,
            },
          }}
          {...bindToggle(popupState)}
        >
          <IconX stroke={1.5} size="1.3rem" />
        </Avatar>
      </ButtonBase>
    </Grid>
  );
};

MobileSearch.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  popupState: PopupState,
};

const WORKSPACES = gql`
  query GetWorkspaces {
    viewer {
      id
      workspaceFavorites {
        nodes {
          workspace {
            name
            id
            __typename
          }
        }
      }
      __typename
    }
  }
`;
// ==============================|| SEARCH INPUT ||============================== //

const SearchSection = ({ setSearchWorkspace }) => {
  let navigate = useNavigate();
  const theme = useTheme();
  const { data, loading, error } = useQuery(WORKSPACES);
  const [workspace, setWorkspace] = useState("");
  const onWorkspaceSelect = (event) => {
    setWorkspace(event.target.value);
    setSearchWorkspace(event.target.value);
    localStorage.setItem("workspace", event.target.value);
  };
  useEffect(() => {
    if (data) {
      if (!localStorage.getItem("workspace")) {
        localStorage.setItem(
          "workspace",
          data.viewer.workspaceFavorites.nodes[0].workspace.id
        );
      }
      if (data.viewer.workspaceFavorites.nodes.length > 0) {
        setWorkspace(localStorage.getItem("workspace"));
        setSearchWorkspace(localStorage.getItem("workspace"));
      } else {
        alert(
          "You need to have at least one Favourite Workspace in Zenhub to access!"
        );
        navigate("/pages/login");
      }
    }
  }, [data]);
  if (loading || error) return;
  return (
    <>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <PopupState variant="popper" popupId="demo-popup-popper">
          {(popupState) => (
            <>
              <Box sx={{ ml: 2 }}>
                <ButtonBase sx={{ borderRadius: "12px" }}>
                  <HeaderAvatarStyle
                    variant="rounded"
                    {...bindToggle(popupState)}
                  >
                    <IconSearch stroke={1.5} size="1.2rem" />
                  </HeaderAvatarStyle>
                </ButtonBase>
              </Box>
              <PopperStyle {...bindPopper(popupState)} transition>
                {({ TransitionProps }) => (
                  <>
                    <Transitions
                      type="zoom"
                      {...TransitionProps}
                      sx={{ transformOrigin: "center left" }}
                    >
                      <Card
                        sx={{
                          background: "#fff",
                          [theme.breakpoints.down("sm")]: {
                            border: 0,
                            boxShadow: "none",
                          },
                        }}
                      >
                        <Box sx={{ p: 2 }}>
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Grid item xs>
                              <MobileSearch
                                popupState={popupState}
                                onWorkspaceSelect={onWorkspaceSelect}
                                workspace={workspace}
                                data={data}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Card>
                    </Transitions>
                  </>
                )}
              </PopperStyle>
            </>
          )}
        </PopupState>
      </Box>
      <Box sx={{ display: { xs: "none", md: "block" }, ml: 2 }}>
        <TextField
          id="standard-select-currency"
          select
          value={workspace}
          onChange={onWorkspaceSelect}
        >
          {data.viewer.workspaceFavorites.nodes.map((w, index) => {
            return (
              <MenuItem key={index} value={w.workspace.id}>
                {w.workspace.name}
              </MenuItem>
            );
          })}
        </TextField>
      </Box>
    </>
  );
};

export default SearchSection;
