// assets
import { IconDashboard } from "@tabler/icons";
import DashboardIcon from '@mui/icons-material/Dashboard';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import SpeedIcon from '@mui/icons-material/Speed';
import QuizIcon from '@mui/icons-material/Quiz';
import RuleIcon from '@mui/icons-material/Rule';

// constant
const icons = { IconDashboard, DashboardIcon, EventRepeatIcon, SpeedIcon, QuizIcon,RuleIcon };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: "dashboard",
  title: "Dashboard",
  type: "group",
  children: [
    {
      id: "default",
      title: "Dashboard",
      type: "item",
      url: "/",
      icon: icons.DashboardIcon,
      breadcrumbs: false,
    },
    {
      id: "scope",
      title: "Scope Change",
      type: "item",
      url: "/scope",
      icon: icons.RuleIcon,
      breadcrumbs: false,
    },
    {
      id: "velocity",
      title: "Velocity Trendline",
      type: "item",
      url: "/velocity",
      icon: icons.SpeedIcon,
      breadcrumbs: false,
    },
    {
      id: "pivot",
      title: "Retrospective",
      type: "item",
      url: "/pivot",
      icon: icons.QuizIcon,
      breadcrumbs: false,
    },
    {
      id: "history",
      title: "Sprint History",
      type: "item",
      url: "/history",
      icon: icons.EventRepeatIcon,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
