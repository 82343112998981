export const calculatePoints = (category, data) => {
  let points = 0;
  let pointsSprint = Array(data.workspace.sprints.nodes.length).fill(0);
  category.issues.forEach((issueFind) => {
    data.workspace.sprints.nodes.forEach((node, index) => {
      if (node.id === issueFind.sprintId) {
        node.issues.nodes.forEach((issue) => {
          if (issue.id === issueFind.issueId) {
            if (!isNaN(issue.estimate?.value)) {
              pointsSprint[index] = pointsSprint[index] + issue.estimate?.value;
              points = points + issue.estimate?.value;
            }
          }
        });
      }
    });
  });
  return pointsSprint;
};

export const random_rgba = () => {
  var o = Math.round,
    r = Math.random,
    s = 255;
  return (
    "rgba(" + o(r() * s) + "," + o(r() * s) + "," + o(r() * s) + "," + 1 + ")"
  );
};
