import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useQuery, gql } from "@apollo/client";
// material-ui

import { Grid } from "@mui/material";
import { IconButton } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
// project imports
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";

import { getDaysArray, parseAssigneesChart } from "utils/parser";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);
const footer = (tooltipItems) => {
  let comp = 0;
  tooltipItems.forEach(function (tooltipItem) {
    comp = tooltipItems[0].dataset.data[0] - tooltipItems[0].raw;
  });
  if (tooltipItems.length > 1) {
    return "Completed: " + comp;
  }
};
export const options = {
  interaction: {
    intersect: false,
    mode: "index",
  },
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Sprint Burn Down Trend",
    },
    tooltip: {
      callbacks: {
        footer: footer,
      },
    },
  },
};

const ASSIGNEESTREND = gql`
  query GetAssigneesTrend($workspace: ID!, $sprint: ID!) {
    workspace(id: $workspace) {
      id
      sprints(filters: { id: { in: [$sprint] } }) {
        nodes {
          id
          name
          startAt
          endAt
          issues {
            nodes {
              id
              title
              closedAt
              estimate {
                value
              }
              assignees(first: 10) {
                nodes {
                  login
                }
              }
              __typename
            }
          }
          __typename
        }
      }
      __typename
    }
  }
`;

const CompletionTrend = ({ workspace, sprint, assignee }) => {
  const { data, loading, error } = useQuery(ASSIGNEESTREND, {
    variables: { workspace, sprint },
  });
  const [chartInfo, setChartInfo] = useState({
    labels: [],
    datasets: [],
  });
  const ref = useRef(null);
  const downloadImage = useCallback(() => {
    const link = document.createElement("a");
    link.download = `SprintBurnDown_${assignee}(${
      data.workspace.sprints.nodes.find((i) => i.id === sprint).name
    }).png`;
    link.href = ref.current.toBase64Image();
    link.click();
  }, [assignee, sprint, data]);
  useEffect(() => {
    if (data) {
      const [dates, numWorkDays] = getDaysArray(
        data.workspace.sprints.nodes[0].startAt,
        data.workspace.sprints.nodes[0].endAt
      );
      const assigneeChart = parseAssigneesChart(data, assignee, dates);
      let idealRemaining = assigneeChart.total;
      let remaining = assigneeChart.total;
      let assigneeFix = [];
      let idealChart = [];
      let idealPerDay = assigneeChart.total / numWorkDays;
      assigneeChart.chart.forEach((day, index) => {
        if (new Date(dates[index]).getTime() < new Date().getTime()) {
          assigneeFix.push(remaining - day);
          remaining -= day;
        } else {
          assigneeFix.push(null);
        }

        if (new Date(dates[index]).getDay() % 6 !== 0) {
          idealChart.push(Math.abs(idealRemaining - idealPerDay));
          idealRemaining -= idealPerDay;
        } else {
          idealChart.push(idealRemaining);
        }
      });
      setChartInfo({
        ...chartInfo,
        labels: dates,
        datasets: [
          {
            label: "Remaining",
            data: assigneeFix,
            backgroundColor: "rgba(54, 162, 235, 0.1)",
            borderColor: "rgb(54, 162, 235)",
            borderWidth: 1,
            stepped: true,
            fill: true,
          },

          {
            label: "Ideal",
            data: idealChart,
            backgroundColor: "rgba(192, 192, 192, 1)",
            borderColor: "rgb(192, 192, 192)",
            borderWidth: 1,
            borderDash: [5, 5],
          },
        ],
      });
    }
  }, [assignee, sprint, data]);

  return (
    <MainCard>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <IconButton onClick={downloadImage}>
            <FileDownloadIcon fontSize="small" />
          </IconButton>
          <Line options={options} data={chartInfo} ref={ref} />
        </Grid>
      </Grid>
    </MainCard>
  );
};
export default CompletionTrend;
