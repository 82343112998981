import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { Typography, Avatar, FormControl } from "@mui/material";
import { useQuery, gql } from "@apollo/client";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import SkeletonPopularCard from "ui-component/cards/Skeleton/PopularCard";
const ASSIGNEESNEXT = gql`
  query GetAssigneesNext($workspace: ID!, $sprint: ID!) {
    workspace(id: $workspace) {
      id
      sprints(filters: { id: { in: [$sprint] } }) {
        nodes {
          id
          issues {
            nodes {
              id
              title
              estimate {
                value
              }
              pipelineIssue(workspaceId: $workspace) {
                pipeline {
                  name
                }
              }
              assignees(first: 9) {
                nodes {
                  login
                }
              }
              __typename
            }
          }
          __typename
        }
      }
      __typename
    }
  }
`;

const headCells = [
  {
    id: "avatar",
    numeric: false,
    disablePadding: true,
    label: "",
  },
  {
    id: "Name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "login",
    numeric: false,
    disablePadding: false,
    label: "Login",
  },
  {
    id: "backlog",
    numeric: false,
    disablePadding: false,
    label: "Backlog",
  },
  {
    id: "todo",
    numeric: false,
    disablePadding: false,
    label: "To Do",
  },
  {
    id: "progress",
    numeric: false,
    disablePadding: false,
    label: "In Progress",
  },
  {
    id: "review",
    numeric: false,
    disablePadding: false,
    label: "In Review",
  },
  {
    id: "total",
    numeric: false,
    disablePadding: false,
    label: "Total O / C",
  },
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ verticalAlign: "bottom" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default function EnhancedTable({ rows, workspace, sprint }) {
  const { data, loading, error } = useQuery(ASSIGNEESNEXT, {
    variables: { workspace, sprint },
  });
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [showNext, setShowNext] = React.useState(false);
  const [nextValues, setNextValues] = React.useState(null);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const onNextActivate = (event) => {
    setShowNext(event.target.checked);
  };
  React.useEffect(() => {
    setNextValues(null);
    if (data) {
      let assigneeData = [];
      data.workspace.sprints.nodes[0].issues.nodes.forEach((issue) => {
        if (issue.estimate) {
          issue.assignees.nodes.forEach((assignee) => {
            // Find if we have the assignee in our list
            const indexAssignee = assigneeData.findIndex(
              (el) => el.login === assignee.login
            );
            // If the assignee is in our list we add the extra points
            if (indexAssignee !== -1) {
              // If the pipeline is not in the assigne yet we add it to the list
              if (
                !assigneeData[indexAssignee].issues[
                  issue.pipelineIssue.pipeline.name.split(" ").pop()
                ]
              ) {
                assigneeData[indexAssignee].issues[
                  issue.pipelineIssue.pipeline.name.split(" ").pop()
                ] = 0;
              }
              assigneeData[indexAssignee].issues[
                issue.pipelineIssue.pipeline.name.split(" ").pop()
              ] += issue.estimate.value;
              // If the assignee is NOT in our list we create the assignee and the points
            } else {
              // Create assignee info
              let assigneeToPush = { ...assignee };
              // Add the pipeline name and Total with 0
              assigneeToPush["issues"] = {};
              assigneeToPush.issues[
                issue.pipelineIssue.pipeline.name.split(" ").pop()
              ] = 0;
              // Add the current pipeline info
              assigneeToPush.issues[
                issue.pipelineIssue.pipeline.name.split(" ").pop()
              ] += issue.estimate.value;
              assigneeData.push(assigneeToPush);
            }
          });
        }
      });
      setNextValues(assigneeData);
    }
  }, [data]);
  if (loading || error || !nextValues) return <SkeletonPopularCard />;
  return (
    <Box sx={{ width: "100%" }}>
      <FormControl>
        <FormGroup sx={{ marginLeft: 2 }}>
          <FormControlLabel
            control={<Switch onChange={onNextActivate} />}
            label="Planning Mode"
          />
        </FormGroup>
      </FormControl>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer
          sx={{
            "&::-webkit-scrollbar": {
              width: 12,
            },
            "&::-webkit-scrollbar-track": {
              background: "#f5f5f7",
              borderRadius: 10,
            },

            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#E6E9FA",
              borderRadius: 10,
            },
          }}
        >
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"small"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
              {stableSort(rows, getComparator(order, orderBy)).map(
                (row, index) => {
                  const totalOpen =
                    row.issues.Backlog?.OPEN ||
                    0 + row.issues.TODO?.OPEN ||
                    0 + row.issues.Progress?.OPEN ||
                    0 + row.issues.Review?.OPEN ||
                    0;
                  const totalClosed =
                    row.issues.Backlog?.CLOSED ||
                    0 + row.issues.TODO?.CLOSED ||
                    0 + row.issues.Progress?.CLOSED ||
                    0 + row.issues.Review?.CLOSED ||
                    0;
                  const nextValuesRow = nextValues.filter(
                    (ele) => ele.login === row.login
                  );
                  const totalOpenNext =
                    nextValuesRow[0]?.issues.Backlog ||
                    0 + nextValuesRow[0]?.issues.TODO ||
                    0 + nextValuesRow[0]?.issues.Progress ||
                    0 + nextValuesRow[0]?.issues.Review ||
                    0;

                  const labelId = `enhanced-table${index}`;
                  return (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell>
                        <Avatar
                          src={row.avatarUrl}
                          aria-haspopup="true"
                          color="inherit"
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        <Typography noWrap>{row.name}</Typography>
                      </TableCell>

                      <TableCell>
                        <Typography noWrap>{row.login}</Typography>
                      </TableCell>
                      <TableCell>
                        {showNext && nextValuesRow[0]?.issues.Backlog ? (
                          <Typography noWrap display="inline" color={"red"}>
                            {`${
                              (row.issues.Backlog?.OPEN || 0) +
                              nextValuesRow[0]?.issues.Backlog
                            }`}
                          </Typography>
                        ) : (
                          <Typography noWrap display="inline">
                            {`${row.issues.Backlog?.OPEN || 0}`}
                          </Typography>
                        )}
                        <Typography display="inline">
                          {row.issues.Backlog?.SHARED ? "*" : null}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {showNext && nextValuesRow[0]?.issues.TODO ? (
                          <Typography noWrap display="inline" color={"red"}>
                            {`${
                              (row.issues.TODO?.OPEN || 0) +
                              nextValuesRow[0]?.issues.TODO
                            }`}
                          </Typography>
                        ) : (
                          <Typography noWrap display="inline">
                            {`${row.issues.TODO?.OPEN || 0}`}
                          </Typography>
                        )}
                        <Typography display="inline">
                          {row.issues.TODO?.SHARED ? "*" : null}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {showNext && nextValuesRow[0]?.issues.Progress ? (
                          <Typography noWrap display="inline" color={"red"}>
                            {`${
                              (row.issues.Progress?.OPEN || 0) +
                              nextValuesRow[0]?.issues.Progress
                            }`}
                          </Typography>
                        ) : (
                          <Typography noWrap display="inline">
                            {`${row.issues.Progress?.OPEN || 0}`}
                          </Typography>
                        )}
                        <Typography display="inline">
                          {row.issues.Progress?.SHARED ? "*" : null}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        {showNext && nextValuesRow[0]?.issues.Review ? (
                          <Typography noWrap display="inline" color={"red"}>
                            {`${
                              (row.issues.Review?.OPEN || 0) +
                              nextValuesRow[0]?.issues.Review
                            }`}
                          </Typography>
                        ) : (
                          <Typography noWrap display="inline">
                            {`${row.issues.Review?.OPEN || 0}`}
                          </Typography>
                        )}
                        <Typography display="inline">
                          {row.issues.Review?.SHARED ? "*" : null}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {showNext && totalOpenNext > 0 ? (
                          <Typography noWrap color={"red"}>
                            {`${totalOpen + totalOpenNext}  / ${totalClosed}`}
                          </Typography>
                        ) : (
                          <Typography
                            noWrap
                          >{`${totalOpen} / ${totalClosed}`}</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
