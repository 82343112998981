import React, { useEffect, useState } from "react";
// material-ui
import { Grid } from "@mui/material";
import { gridSpacing } from "store/constant";
import { getRoadmapData } from "../../../utils/fetcherRoadmap";
import Switch from "@mui/material/Switch";
import {
  Gantt,
  Task,
  EventOption,
  StylingOption,
  ViewMode,
  DisplayOption,
} from "gantt-task-react";
import "gantt-task-react/dist/index.css";
let taskes = [
  {
    start: new Date(2020, 1, 1),
    end: new Date(2020, 1, 2),
    name: "Idea",
    id: "Task 0",
    type: "task",
    progress: 45,
    isDisabled: true,
    styles: { progressColor: "#ffbb54", progressSelectedColor: "#ff9e0d" },
  },
];
const RoadmapPage = ({ workspace, loggedIn }) => {
  const [isChecked, setIsChecked] = useState(true);
  const [tasks, setTasks] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      setTasks(null);
      const roadmapData = await getRoadmapData(workspace);
      console.log(roadmapData);

      const tasks = roadmapData.flatMap((item) => {
        const task = {
          start: item.start,
          end: item.end,
          name: item.name,
          id: "release" + item.id,
          type: "project",
          progress: item.progress,
          isDisabled: item.isDisabled,
          hideChildren: true,
          styles: {
            backgroundColor: "#ff0000",
            backgroundSelectedColor: "#800000",
          },
        };

        const epics = item.epics.flatMap((epic) => {
          const epicTask = {
            start: epic.start,
            end: epic.end,
            name: epic.name,
            id: "epic" + epic.id,
            type: "project",
            progress: epic.progress,
            isDisabled: epic.isDisabled,
            hideChildren: true,
            project: "release" + item.id,
          };

          const issues = epic.issues.map((issue) => {
            return {
              start: issue.start,
              end: issue.end,
              name: issue.name,
              id: epic.id + issue.id,
              type: "task",
              progress: issue.progress,
              isDisabled: issue.isDisabled,
              project: "epic" + epic.id,
              hideChildren: false,
            };
          });

          return [epicTask, ...issues];
        });

        return [task, ...epics];
      });

      setTasks(tasks);
      console.log(tasks);
    };

    if (workspace) {
      fetchData();
    }
  }, [workspace]);

  const handleExpanderClick = (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };
  return (
    <Grid container spacing={gridSpacing}>
      <Switch onChange={(e) => setIsChecked(e.target.checked)} defaultChecked />
      <Grid item xs={12}>
        {tasks ? (
          <Gantt
            tasks={tasks}
            viewMode={ViewMode.Month}
            listCellWidth={isChecked ? "155px" : ""}
            onExpanderClick={handleExpanderClick}
            ganttHeight={"600px"}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

export default RoadmapPage;
