// import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { drawerWidth } from "store/constant";
// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  CardActions,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Popper,
  Stack,
  Link,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

import heartFeed from "../../../assets/images/heart-feed.svg";
import blockbit from "../../../assets/images/blockbites.svg";
import arrow from "../../../assets/images/icons/arrow.svg";
import { getDataRepoInfo } from "utils/fetcher";

const Footer = ({ open }) => {
  const [repoInfo, setRepoInfo] = useState(null);
  useEffect(() => {
    getDataRepoInfo().then((response) => setRepoInfo(response));
  }, []);
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        bgcolor: "#000F57",
        width: open ? `calc(100% - ${drawerWidth}px)` : "100%",
        marginLeft: open ? drawerWidth + "px" : "0",
        transition: "0.2s",
      }}
    >
      <Stack
        direction="column"
        spacing={8}
        sx={{
          maxWidth: "1240px",
          width: "100%",
          p: "56px 100px 32px 100px",
          color: "white",
        }}
      >
        <Stack justifyContent="space-between" direction="row">
          <Stack
            justifyContent="space-between"
            direction="column"
            sx={{ color: "white" }}
          >
            <Typography
              variant="h2"
              sx={{ color: "white", textTransform: "uppercase", mb: "40px" }}
            >
              Explore
            </Typography>
            <Stack
              justifyContent="space-between"
              direction="column"
              spacing={2.5}
              sx={{
                fontSize: "16px",
              }}
            >
              <Link
                target="_blank"
                rel="noreferrer"
                href="http://blockbit.es/"
                sx={{
                  color: "white",
                }}
                underline="hover"
              >
                <img
                  src={arrow}
                  alt="arrow"
                  loading="lazy"
                  style={{ marginRight: "8px" }}
                />
                Blockbites
              </Link>
              <Link
                target="_blank"
                rel="noreferrer"
                href="https://defdone.com/"
                sx={{
                  color: "white",
                }}
                underline="hover"
              >
                <img
                  src={arrow}
                  alt="arrow"
                  loading="lazy"
                  style={{ marginRight: "8px" }}
                />
                Defdone
              </Link>
              <Link
                target="_blank"
                rel="noreferrer"
                href="https://casper.network/en-us/"
                sx={{
                  color: "white",
                }}
                underline="hover"
              >
                <img
                  src={arrow}
                  alt="arrow"
                  loading="lazy"
                  style={{ marginRight: "8px" }}
                />
                Casper Network
              </Link>
              <Link
                target="_blank"
                rel="noreferrer"
                href="https://chainlendar.io/"
                sx={{
                  color: "white",
                }}
                underline="hover"
              >
                <img
                  src={arrow}
                  alt="arrow"
                  loading="lazy"
                  style={{ marginRight: "8px" }}
                />
                Chainlendar
              </Link>
            </Stack>
          </Stack>
          <Stack>
            <Typography
              variant="h2"
              sx={{ color: "white", textTransform: "uppercase", mb: "10px" }}
            >
              Feedback
            </Typography>
            <Stack direction="column" spacing={8}>
              <Typography sx={{ fontSize: "16px" }}>
                Let us know what can we improve, your opinion matters!
              </Typography>
              <Button
                size="large"
                variant="contained"
                href={"https://azhd-blockbites.canny.io/"}
                target="_blank"
                sx={{
                  gap: "10px",
                  bgcolor: "red",
                  maxWidth: "190px",
                  height: "60px",
                  fontSize: "18px",
                  "&:hover": {
                    bgcolor: "#ac0719",
                  },
                }}
              >
                Feedback
                <img src={heartFeed} alt="feedback" loading="lazy" />
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          justifyContent="space-between"
          direction="row"
          alignItems="flex-end"
        >
          <Typography
            sx={{ alignSelf: "flex-end" }}
            className="footer__copyright"
          >
            {repoInfo ? `${repoInfo.tag.name} (${repoInfo.commit})` : ""}
          </Typography>

          <Stack alignItems="flex-end">
            <Link href="https://blockbit.es" target="_blank" rel="noreferrer">
              <img
                width="150px"
                src={blockbit}
                alt="blockbit.es"
                className="footer__blockbit"
                loading="lazy"
              />
            </Link>
            <Typography>© {new Date().getFullYear()} Blockbites</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Footer;
