const token = process.env.REACT_APP_TOKEN;
// Get Scope Change Information
const getSprintScope = (workspace, sprint) => {
  const responseInfo = fetch("https://api.zenhub.com/public/graphql", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("zhToken"),
    },
    body: JSON.stringify({
      query: `
          {
            workspace(id: "${workspace}") {
              sprints(filters: { id: { in: ["${sprint}"] } }) {
                nodes {
                  name
                  startAt
                  scopeChange{
                    nodes {
                      effectiveAt
                      estimateValue
                      action
                    }
                  }
                }   
              }
            }
          }
      `,
    }),
  });
  return responseInfo;
};

// Calculate the points and add them to the corresponding sprint
export async function addPointsIssue(response, localResponse) {
  response.forEach((v) => {
    if (v.name === localResponse.data.workspace.sprints.nodes[0].name) {
      v.add += localResponse.data.workspace.sprints.nodes[0].scopeChange.nodes
        .filter((element) => element.action === "ISSUE_ADDED")
        .reduce((accumulator, object) => {
          return accumulator + object.estimateValue;
        }, 0);
      v.rem += localResponse.data.workspace.sprints.nodes[0].scopeChange.nodes
        .filter((element) => element.action === "ISSUE_REMOVED")
        .reduce((accumulator, object) => {
          return accumulator + object.estimateValue;
        }, 0);
      v.date = localResponse.data.workspace.sprints.nodes[0].startAt
        .split("T")[0]
        .split("-")
        .join("");
    }
  });
}
// Parse Socpe change points for a specific workspace with an array of sprints
export async function parseSprintScope(workspace, sprintes) {
  let response = [];
  const now = new Date().getTime();
  // Launch all the requests for every sprint in parallel
  await Promise.all(
    sprintes.map(async (sprint) => {
      const sprintStart = new Date(sprint.startAt).getTime();
      if (sprintStart < now) {
        response.push({
          name: sprint.name,
          id: sprint.id,
          date: "",
          add: 0,
          rem: 0,
        });
        const contents = await getSprintScope(workspace, sprint.id);
        await contents.json().then((data) => {
          addPointsIssue(response, data);
        });
      }
    })
  );
  // Sort the response in date order
  return response.sort(function (a, b) {
    return a.date > b.date ? 1 : a.date < b.date ? -1 : 0;
  });
}

// Get Retro Data Information
const getSprintRetroData = (workspace, sprint, cursor) => {
  const responseInfo = fetch("https://api.zenhub.com/public/graphql", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("zhToken"),
    },
    body: JSON.stringify({
      query: `
            {
             workspace(id: "${workspace}") {
                  sprints(query:"${sprint}") {
                    nodes {
                      id
                      startAt
                      name
                      issues(first: 18, after: "${cursor}") {
                        nodes {
                          id
                          htmlUrl
                          state
                          title
                          estimate {
                            value
                          }
                          assignees {
                            nodes {
                              name
                            }
                          }
                        }
                        pageInfo {
                          hasNextPage
                          endCursor
                        }
                      }
                    }
                  }    
                
              
            }
          }
      `,
    }),
  });
  return responseInfo;
};

// Calculate the points and add them to the corresponding sprint
export async function parseSptrintRetroData(response, localResponse) {
  response.forEach((v) => {
    if (v.id === localResponse.data?.workspace?.sprints.nodes[0].id) {
      v.issues.push(
        ...localResponse.data.workspace.sprints.nodes[0].issues.nodes
      );
    }
  });
}
// Parse Retro Data for a specific workspace with an array of sprints
export async function parseRetroData(workspace, sprintes) {
  let response = [];
  const now = new Date().getTime();
  // Launch all the requests for every sprint in parallel
  await Promise.all(
    sprintes.map(async (sprint) => {
      let cursor = "";
      let localResponse;
      const sprintStart = new Date(sprint.startAt).getTime();
      if (sprintStart < now) {
        response.push({
          name: sprint.name,
          id: sprint.id,
          startAt: sprint.startAt,
          issues: [],
        });
        // Keep fetching info until we get no more pages.
        while (true) {
          const contents = await getSprintRetroData(
            workspace,
            sprint.name,
            cursor
          );
          await contents.json().then((data) => {
            if (!data.errors) {
              localResponse = data;
            } else {
              localResponse.data.workspace.sprints.nodes[0].issues.pageInfo.hasNextPage = false;
            }
          });
          // Call the async function to fill the response with the info just received
          parseSptrintRetroData(response, localResponse);
          // When there are not more pages break the loop
          if (
            localResponse.data.workspace.sprints.nodes[0].issues.pageInfo
              .hasNextPage === false
          ) {
            break;
            // When more pages save the cursor and fetch again
          } else {
            cursor =
              localResponse.data.workspace.sprints.nodes[0].issues.pageInfo
                .endCursor;
          }
        }
      }
    })
  );
  // Sort the response in date order
  return response.sort(function (a, b) {
    return a.date > b.date ? 1 : a.date < b.date ? -1 : 0;
  });
}

// Get information for Footer
export async function getDataRepoInfo() {
  const [responseCommit, responseTags] = await Promise.all([
    fetch(
      `https://api.github.com/repos/block-bites/zenhub-advanced-dashboard/commits/master`,
      {
        method: "GET",
        headers: {
          Authorization: token ? "Bearer " + token : null,
        },
      }
    ),
    fetch(
      `https://api.github.com/repos/block-bites/zenhub-advanced-dashboard/tags`,
      {
        method: "GET",
        headers: {
          Authorization: token ? "Bearer " + token : null,
        },
      }
    ),
  ]);

  const commit = await responseCommit.json();
  const tags = await responseTags.json();
  return {
    commit: commit.sha?.slice(0, 7) || "XXXXXXX",
    tag: tags[0] || { name: "v1.0.0" },
  };
}
